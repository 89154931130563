import Auth from "shared/components/Auth";
import { Layout } from "antd";
import { Formik } from "formik";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import Modal from "shared/components/Modal";
import { NAME_SPACES } from "shared/locales/constants";
import { StoreContext } from "shared/store";
import authApi from "utils/api/auth";
import { AUTH_STYLE, PATHS } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import { useMutation } from "utils/hooks";
import validation from "./validation";

const { Content } = Layout;

export default ({ history }) => {
  const { t } = useTranslation(NAME_SPACES.AUTH);
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");
  const { setSource, setTokens } = useContext(StoreContext);
  const location = useLocation();
  const href = window.location.href;
  const index = href.indexOf("&source");
  const link = href.slice(0, index);
  useEffect(() => {
    const { source: parsedSource } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    parsedSource && setSource(parsedSource);
  }, [location.search, setSource]);
  const [linkSignIn] = useMutation(authApi.singInWithLink, {
    onSuccess: ({ data: { accessToken } }) => {
      setTokens({ accessToken });
      history.push(PATHS.PUBLIC.AUTH.RESET_PASSWORD);
    },
    onError: () => {
      setMessage(t("REQUEST_PASSWORD_KO"));
      setVisible(true);
    },
  });
  const inputs = [{ name: "passcode", placeholder: "Code de vérification" }];
  return (
    <Layout className="layout">
      <Content className="layout--container auth">
        <div className="section--login">
          <Formik
            initialValues={{
              passcode: "",
            }}
            onSubmit={({ passcode }) => {
              linkSignIn({
                passcode,
                url: link,
              });
            }}
            validationSchema={validation(
              t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
            )}
          >
            {({ isValid, handleSubmit, ...formProps }) => (
              <Auth
                title={"Vérification de l'identité"}
                subtitle={"Veuillez insérer le code reçu par email"}
                inputs={inputs.map((input) => ({
                  ...input,
                  ...bindInputProps({ name: input.name, ...formProps }),
                }))}
                submitText={"Valider"}
                onSubmit={handleSubmit}
                forgotPasswordText={t("SIGN_IN")}
                onPasswordForgot={() => history.push(PATHS.PUBLIC.AUTH.SIGN_IN)}
                style={AUTH_STYLE}
              />
            )}
          </Formik>
        </div>
      </Content>
      <Modal visible={visible} title={t("MODAl_TITLE")} message={message} />
    </Layout>
  );
};
