import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const Button = ({ title, icon, onClick, bg, containerStyle, textStyle }) => (
  <div
    onClick={onClick}
    className={"button"}
    style={{ backgroundColor: bg, ...containerStyle }}>
    {icon && <div className={"button-icon"}>{icon}</div>}
    <p className={"button-title"} style={{ ...textStyle }}>
      {title}
    </p>
  </div>
);

Button.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  bg: PropTypes.string,
  containerStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

Button.defaultProps = {
  title: "",
  icon: null,
  onClick: () => {},
  bg: "#E2E7EE",
  containerStyle: {},
  textStyle: {},
};
export default Button;
