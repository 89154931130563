import React from "react";
import { Input as InputField } from "antd";
import "./style.scss";
import PropTypes from "prop-types";

const Input = ({ placeholder, hidePlaceholder, errors, touched, ...rest }) => {
  return (
    <div className={"input"}>
      {!hidePlaceholder && <p className={"input-placeholder"}>{placeholder}</p>}
      <InputField
        className={`${touched && errors && "input-input-error"} input-input`}
        placeholder={placeholder}
        {...rest}
      />
      {touched && <small className={"input-error"}>{errors}</small>}
    </div>
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
  hidePlaceholder: PropTypes.bool,
};
Input.defaultProps = {
  placeholder: "",
  hidePlaceholder: false,
};
export default Input;
