import React from "react";
import PropTypes from "prop-types";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import "./style.scss";

const AuthForm = ({
  title,
  subtitle,
  inputs,
  submitText,
  forgotPasswordText,
  onSubmit,
  onPasswordForgot,
  style = {},
}) => (
  <div className={"auth-container"}>
    <p className={"auth-title"} style={style.title}>
      {title}
    </p>
    <p className={"auth-subtitle"} style={style.subtitle}>
      {subtitle}
    </p>
    {inputs.map(({ placeholder, ...rest }) => (
      <div key={`input-${placeholder}`} className={"auth-input-container"}>
        <Input placeholder={placeholder} {...rest} />
      </div>
    ))}
    <Button
      title={submitText}
      bg={"#FE5D80"}
      containerStyle={style.button}
      textStyle={{
        color: "#ffffff",
        paddingTop: 7,
        paddingBottom: 7,
        fontSize: 16,
      }}
      onClick={onSubmit}
    />
    <p
      className={"auth-forgot-pwd"}
      style={style.forgotPassword}
      onClick={onPasswordForgot}
    >
      {forgotPasswordText}
    </p>
  </div>
);

AuthForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      placeholder: PropTypes.string,
      hidePlaceholder: PropTypes.bool,
    })
  ),
  submitText: PropTypes.string,
  forgotPasswordText: PropTypes.string,
  onSubmit: PropTypes.func,
  onPasswordForgot: PropTypes.func,
};

AuthForm.defaultProps = {
  title: "",
  subtitle: "",
  inputs: [],
  submitText: "",
  forgotPasswordText: "",
  onSubmit: () => {},
  onPasswordForgot: () => {},
};
export default AuthForm;
